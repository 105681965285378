import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const ProcessOfStartingACampaign = ({ location }) => {
  const title = "Flow from PARKLoT application to the start of the campaign"
  const list = [
    { title: "Frequently Asked Questions", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader lang="en-US" />
      <Layout lang="en-US">
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="This section describes the process to start Instant Win. The basic flow is as follows: application - data preparation, invoicing, payment, and campaign creation. The data to be prepared varies depending on the type of campaign."
          pagepath={location.pathname}
        />
        <Breadcrumb title="Frequently Asked Questions" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              What is the process to start the campaign?
            </h1>
            <p className="qa-page__answer">
              The basic flow is as follows: application - data preparation and
              payment - campaign creation - campaign start.
              <br />
            </p>
            <p className="qa-page__text">
              This page explains how to start the Basic Plan (Instant Win
              Campaign). For information on organizing non-Instant Win campaigns
              such as hashtag campaigns,&nbsp;
              <a
                className="qa-page__text-link"
                href="https://hub.park-lot.com/contact"
                target="_blank"
                rel="noreferrer"
              >
                please contact us via the Contact Us form.
              </a>
            </p>

            <div className="qa-page__detail-container">
              <div>
                <h2 className="qa-page__h2">Steps to start the campaign</h2>

                <div className="qa-page__text">
                  <ol className="qa-page__ol">
                    <li>
                      <a
                        className="qa-page__text-link"
                        href="/forms/apply"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Please apply using the application form.
                      </a>
                    </li>
                    <li>
                      Please prepare the necessary information and data for the
                      campaign. The required information and data are as
                      follows: Twitter account, Twitter developer account,
                      campaign name, gift, campaign period, and image for tweet
                      submission.
                    </li>
                    <li>
                      Payment must be made at least 3 business days prior to the
                      start of the campaign.
                    </li>
                    <li>
                      After confirming your payment, we will process your
                      Twitter account so that you can log in with PARKLoT.
                    </li>
                    <li>Please log in to PARKLoT to create your campaign.</li>
                    <li>
                      At the time of the event, a tweet will be automatically
                      posted on Twitter to start the campaign. Drawing will be
                      done automatically and winners can be confirmed from the
                      management screen.
                    </li>
                  </ol>
                </div>

                <h3 className="qa-page__h3">
                  Why do I need a Twitter developer account?
                </h3>

                <p className="qa-page__text">
                  <a
                    className="qa-page__text-link"
                    href="https://developer.twitter.com/en/apply-for-access"
                    target="_blank"
                    rel="noreferrer"
                  >
                    A Twitter developer account
                  </a>
                  &nbsp;is required to retrieve retweet users and automatically
                  send direct messages using the Twitter API. We ask that you
                  obtain this information through the Twitter account that will
                  host the campaign.
                </p>

                <p className="qa-page__text">
                  It takes 3 days to 2 weeks from application to acceptance for
                  a Twitter developer account. Please make sure to apply 2 weeks
                  prior to the campaign.
                </p>

                <h3 className="qa-page__h3">
                  I don't know how to apply for a Twitter developer account.
                </h3>

                <p className="qa-page__text">
                  We can apply for a Twitter developer account on your behalf.
                  Please let us know when you apply.
                </p>

                <h3 className="qa-page__h3">
                  Can I prepare campaign images appropriately?
                </h3>

                <p className="qa-page__text">
                  What do participants see in the campaign images and what do
                  the gifts look like? If I participate, am I likely to win? in
                  an instant. Therefore, it is no exaggeration to say that the
                  images in your campaign will determine the success of your
                  campaign. We recommend that you spend as much time and budget
                  as possible to create your images. We can also create campaign
                  images on your behalf. Please let us know at the time of
                  application if you would like us to do so.
                </p>
              </div>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default ProcessOfStartingACampaign
